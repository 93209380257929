import React, { useState, useEffect } from 'react';
import ResourceTabs from '../common/ResourceTabs';
import TAP from '../../models/entities/TAP';
import Filter from '../common/tables/Filter';

type TAPsFilters = {
    searchGeneral: string;
    dateRangeFrom: Date;
    dateRangeTo: Date;
    showResources: string;
};

interface TemporaryAccessPassFiltersProps {
    approvalsInfo: TAP[];
    onBehalfOfInfo: TAP[];
    tapInfo: TAP[];
    isTapDataLoading: boolean;
    areMyApprovalsLoading: boolean;
    setFileteredTAPS: any;
    tab: string;
    setTab: any;
}

const TemporaryAccessPassFilters = ({
    approvalsInfo,
    onBehalfOfInfo,
    tapInfo,
    isTapDataLoading,
    areMyApprovalsLoading,
    setFileteredTAPS,
    tab,
    setTab
}: TemporaryAccessPassFiltersProps) => {
    const resourceTabs = [];
    resourceTabs.push({
        item1: `My TAP Requests`,
        item2: null,
    });
    resourceTabs.push({
        item1: `My Approvals`,
        item2: null
    });
    resourceTabs.push({
        item1: `On Behalf Of`,
        item2: null
    });
    const defaultFilters = {
        searchGeneral: '',
        dateRangeFrom: new Date('January 01, 2000 23:15:30'),
        dateRangeTo: new Date(),
        showResources: `My TAP Requests`,
    } as TAPsFilters;

    const filterTAPSRequests = () => {
        let filtered = [];
        if (filter.filters.showResources == 'My Approvals') {
            setTab('My Approvals');
            filtered = approvalsInfo;
        } else if (filter.filters.showResources == 'My TAP Requests'){
            setTab('My TAP Requests');
            filtered = tapInfo;
        } else {  
            setTab('On Behalf Of');
            filtered = onBehalfOfInfo;
        }
        setFileteredTAPS(filtered);
    };

    useEffect(() => {
        filter.updateFilterFunction(filterTAPSRequests);
    }, [tapInfo]);

    useEffect(() => {
        filter.filter();
        filter.updateFilter(tab, 'showResources')
    }, []);

    const [filter] = useState<Filter<TAPsFilters>>(new Filter(filterTAPSRequests, defaultFilters));

    return (
        <>
            {filter && (
                <ResourceTabs
                    allResources={resourceTabs}
                    filter={filter}
                    disabled={isTapDataLoading || areMyApprovalsLoading}
                />
            )}
        </>
    );
};

export default TemporaryAccessPassFilters;
