import * as React from 'react';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import TAP from '../../models/entities/TAP';
import { AlertVariant, CircleExclamationMarkIcon, Grid, ProgressBar, Table, TextPassage } from "react-unity";
import { useState, useEffect } from 'react';
import TAPModal from './components/TAPModal';
import TAPModalOnBehalfOf from './components/TAPModalOnBehalfOf';
import TemporaryAccessPasswordTableRow from './TemporaryAccessPasswordTableRow';
import TemporaryAccessPassFilters from './TemporaryAccessPassFilters';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import TemporaryAccessPasswordService from '../../services/TemporaryAccessPasswordService';
import './TemporaryAccessPassword.css';
import AlertModal from '../common/modals/AlertModal';
import Paginator from '../common/tables/Paginator';
import moment from 'moment';
import ReactButton from '../common/ReactButton';

const TemporaryAccessPassword = () => {

    const darkModeEnabled = localStorage.getItem('theme') === 'dark';
    const [isTapDataLoading, setIsTapDataLoading] = useState<boolean>(true);
    const [areMyApprovalsLoading, setAreMyApprovalsLoading] = useState<boolean>(true);
    const [tapInfo, setTapInfo] = useState<TAP[]>([]);
    const [approvalsInfo, setApprovalsInfo] = useState<TAP[]>([]);
    const [onBehalfOfInfo, setOnBehalfOfInfo] = useState<TAP[]>([]);
    const [tapsOnPage, setTapsOnPage] = useState<TAP[]>([]);
    const [progressLabel, setProgressLabel] = useState<string>('Loading...');
    const [tab, setTab] = useState<string>(
        window.location.href.substring(window.location.href.lastIndexOf('?') + 1) == 'approval'
        ? 'My Approvals'
        : 'My TAP Requests'
    );
    const [filteredTAPS, setFileteredTAPS] = useState<TAP[]>([]);
    const [tapModal, setTapModal] = useState<boolean>(false);
    const [tapModalOnBehalfOf, setTapModalOnBehalfOf] = useState<boolean>(false);
    const [isLastRequestTimeFinished, setIsLastRequestTimeFinished] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const temporaryAccessPasswordService = new TemporaryAccessPasswordService();
    const [endModal, setEndModal] = useState<AlertBanner>({ visible: false });
	const [submissionAlert, setSubmissionAlert] = useState<AlertBanner>({
		visible: false,
		text: ''
	});
    const [ alertBanner, setAlertBanner ] = useState<AlertBanner>({
		visible: false,
        variant: 'default'
	});
    const [inProgressRequest, setInProgressRequest] = useState<boolean>(false);
    const [tapass, setTapass] = useState<string>('');
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [countDown, setCountDown] = useState(null);

    const retreiveTaps = async (isRefresh: boolean) => {
		setIsTapDataLoading(true);
		setAreMyApprovalsLoading(true);
		setProgressLabel(`${isRefresh ? 'Reloading' : 'Loading'} your Temporary Access Pass Requests...`);

		try {
			const taps = await temporaryAccessPasswordService.getAll();
            taps.nonApprovedTaps > 1 && setAlertBanner({visible: true, text: 'Next Request must be supervisor approved', variant: 'default'})
            setTapInfo(mapRequests(taps.requests));
            const lastRequested = taps.requests.pop();
            if (lastRequested != null) {
                const lastRequestFinishTime = moment.utc(lastRequested?.startingTime).local().add(1, 'hours');
                if (lastRequestFinishTime >= moment()) {
                    setCountDown((moment().diff(lastRequestFinishTime, 'milliseconds')) * -1);
                    setIsLastRequestTimeFinished(false);
                }
                if (lastRequested?.approvalStatus == 'Requested' || (lastRequested?.approvalStatus == 'Approved' && lastRequested?.startingTime == null)) {
                    setInProgressRequest(true);
                }
            }
			setIsTapDataLoading(false);
			setProgressLabel(`${isRefresh ? 'Reloading' : 'Loading'} your Temporary Access Password Requests...`);
			setApprovalsInfo(mapRequests(taps.approvals));
            setOnBehalfOfInfo(mapRequests(taps.onBehalfOf));
			setAreMyApprovalsLoading(false);
		} catch (err) {
			handleEndModal('Temporary Access Password could not be retrieved.', 'error', 10000);
		}
	};

    const mapRequests = (tap: TAP[]) => {
		return(tap.map(a => new TAP(a))
		.sort((a, b) => a.createdTime <= b.createdTime ? 1 : -1));
	};

    const handleEndModal = (text: string, variant: AlertVariant, timeout: number) => {
		setEndModal({
			visible: true,
			text,
			variant,
		});
		setTimeout(() => endAndRedirect(), timeout);
	};

    const endAndRedirect = () => {
		setEndModal({ visible: false });
	};

    useEffect(() => {
        retreiveTaps(false);
        setTimeout(() => {
            handleAlertClose();
        }, 5000);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDown - 1000);
        }, 1000);
        const [days, hours, minutes, seconds] = getReturnValues(countDown);
        setMinutes(minutes);
        setSeconds(seconds);
        return () => clearInterval(interval);
    }, [countDown]);

    const getReturnValues = (countDown: number) => {
        // calculate time left
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

        return [days, hours, minutes, seconds];
    };

    const handleAlertClose = () => {
		setSubmissionAlert({
			visible: false,
			text: ''
		});
	};

    useEffect(() => {
        setTimeout(() => {
            setIsLastRequestTimeFinished(true);
        }, countDown);
    }, [countDown]);

    const handleSubmitTap = async (e) => {
        try{
            setLoading(true);
            const response = await temporaryAccessPasswordService.createTap();
            if (response.approvalStatus != 'Requested' && response.taPass != null) {
                setTapass(response.taPass);
                setTapModal(true);
            }else{
                setEndModal({ 
                    visible: true,
                    text: `Your request has been sent for supervisor approval`,
                    variant: 'success'
                });
            }
            setIsLastRequestTimeFinished(false);
            setCountDown(moment().add(1, 'hours')); //60 minutes = 36000000
            setLoading(false);
            retreiveTaps(true);
        }
        catch(err){
            setEndModal({ 
                visible: true,
                text: err.response?.data.message || `An error occurred. Please try again later.`,
                variant: 'error'
            });
            setLoading(false);
        }
    }

    const getFilterProps = () => {
		return {
            approvalsInfo,
            tapInfo,
            onBehalfOfInfo,
            isTapDataLoading,
            areMyApprovalsLoading,
            setFileteredTAPS,
            tab,
            setTab
		};
	};

    return (
        <>
            <SectionHomeWrapper title="Temporary Access Password">
                {(isTapDataLoading || areMyApprovalsLoading) && (
                    <ProgressBar className="em-u-margin-top-half" indeterminate hideValueLabel label={progressLabel} />
                )}
                <TextPassage>
                    <p>
                        Remember that after two requests in the same week, your supervisor's approval will be required.
                    </p>
                </TextPassage>
                {(!isTapDataLoading && !areMyApprovalsLoading) && (
                    <>
                        <Grid variant="2-up">
                            <Grid.Item>
                                {tab == 'On Behalf Of' ?
                                    <ReactButton
                                        loadingVariant='secondary'
                                        handleUpdateSubmit={() => setTapModalOnBehalfOf(true)}
                                        isLoading={loading}
                                        variant='primary'
                                        disabled={ false }
                                        name={ 'Request TAP On Behalf Of' }
                                    />
                                    :
                                    <ReactButton
                                        loadingVariant='secondary'
                                        handleUpdateSubmit={(e) => handleSubmitTap(e)}
                                        isLoading={loading}
                                        variant='primary'
                                        disabled={tab == 'My Approvals' || !isLastRequestTimeFinished || loading || inProgressRequest}
                                        name={isLastRequestTimeFinished ? `Request TAP` : `Your current passcode expires in ${minutes.toLocaleString()}:${seconds.toLocaleString()} minutes.`}
                                    />}
                            </Grid.Item>
                        </Grid>
                        {(alertBanner.visible && tab != 'On Behalf Of') &&
                            <TextPassage>
                                <div className='warning-field' id='text-passage'>
                                    <CircleExclamationMarkIcon size='large' style={{marginRight: '10px'}} />
                                    {`Please note: ${alertBanner.text}`}
                                </div>
                            </TextPassage>
                        }
                        <br/>
                        <TemporaryAccessPassFilters {...getFilterProps()} />
                        <Table>
                            <Table.Head>
                                <Table.Head.Row>
                                    { tab == 'My Approvals' 
                                    ? <Table.Head.Cell> Request Date </Table.Head.Cell>
                                    : <Table.Head.Cell> TAP Start Date </Table.Head.Cell>}
                                    <Table.Head.Cell className='em-u-text-align-center'> Approval Required </Table.Head.Cell>
                                    <Table.Head.Cell className='em-u-text-align-center'> Status </Table.Head.Cell>
                                    { tab == 'My Approvals' ? <Table.Head.Cell className='em-u-text-align-center'> Requestor </Table.Head.Cell>
                                    : (tab == 'On Behalf Of' ? <Table.Head.Cell className='em-u-text-align-center'> Employee </Table.Head.Cell>
                                    : <Table.Head.Cell className='em-u-text-align-center'> Approver </Table.Head.Cell>)
                                    }
                                    <Table.Head.Cell className='em-u-text-align-center'> Actions </Table.Head.Cell>
                                </Table.Head.Row>
                            </Table.Head>
                            <Table.Body>
                                {tapsOnPage.map((tap, index) =>
                                    <TemporaryAccessPasswordTableRow
                                        key={index}
                                        tap={tap}
                                        setTapModal={setTapModal}
                                        tab={tab}
                                        setEndModal={setEndModal}
                                        retreiveTaps={retreiveTaps}
                                        temporaryAccessPasswordService={temporaryAccessPasswordService}
                                        setTapass={setTapass}
                                    />
                                )}
                            </Table.Body>
                        </Table>
                        <Table

                            footer={
                                <Paginator
                                    data={filteredTAPS}
                                    totalItems={filteredTAPS.length}
                                    onPageChange={(taps: TAP[]) => {
                                        setTapsOnPage(taps);
                                    }}
                                />
                            }
                        ></Table>
                    </>
                )}
            </SectionHomeWrapper>
            <AlertModal {...endModal} willTimeout={false} onClose={endAndRedirect} />
            <TAPModal
                visible={tapModal}
                setVisible={setTapModal}
                tap={tapass}
            />
            <TAPModalOnBehalfOf
                visible={tapModalOnBehalfOf}
                setVisible={setTapModalOnBehalfOf}
                tapService={temporaryAccessPasswordService}
                refreshTap={retreiveTaps}
            />
        </>

    );
}

export default TemporaryAccessPassword;